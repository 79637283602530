import './App.css';
import { Col, Row, Container, Alert, Spinner } from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import { useState } from 'react';

function App() {
  const [show, setShow] = useState(false);
  const [spin, setSpin] = useState(false);
  const [mail, setMail] = useState(null);
  const [showError, setShowError] = useState(null);

  const sendMail=()=>{
    setSpin(true)
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
    let mailValid = pattern.test(mail)
    if(mailValid){
      setShowError(false)
      var myHeaders = new Headers();
       
        myHeaders.append("to", "maria@tardigradastudio.com");
        myHeaders.append("message", `You have received a new enquiry from your website. User email: ${mail}`);
        myHeaders.append("Access-Control-Allow-Origin", '*');
        myHeaders.append("subject", "Spade & Plate Website");
        myHeaders.append("X-Api-Key", "lHKxbKopFL8SQuXOriSJ79QrYKokXXTh97fTPkOO");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://vs68e34w83.execute-api.ap-south-1.amazonaws.com/default/twentyfox-mailer", requestOptions)
        .then(response =>response.json())
        .then(result=>{
          if(result.status=="Ok"){
            setShowError(false);
            setShow(true)
            setTimeout(() => {
              setShow(false)
            }, 3000);
            setSpin(false)
          }
        })
        .catch(error => {
          setShowError("Can't complete the request, please try again.")
          setTimeout(() => {
            setShowError(null)
          }, 3000);
          setSpin(false)
        });
    } else {
      setShowError("Please enter a valid email.")
      setTimeout(() => {
        setShowError(null)
      }, 3000);
      setSpin(false)
    }
  }

  return (
    <div>
      <div className="App app">
        <Container fluid>
          <img alt="Logo" src={require('./Images/02.S_P_Logo_Sahara.png')} className="logo" width='13.3%' height='13.3%'/>
          <Row>
            <p className="canelaL title">
              BEAUTIFUL THINGS FOR GARDENING <br/>& GATHERING
            </p>
          </Row>
          <Row className="gx-5 gallery">
            <Col md={4}>
              <img alt="Gardening" src={require('./Images/jonathan-kemper-oHCalaoeD0Q-unsplash.jpg')} className="gallery--image"/>
              <p className="canelaL gallery--title">Our Story</p>
              <p className="spadeL gallery--description">Spade and Plate is a gardening-lifestyle brand that provides high quality provisions for your garden and dinner table. Our team is a handful of passionate gardeners and creative professionals. We design and source implements that will make gardening and entertaining more enjoyable, beautiful and sustainable. Working closely with artisans and companies that share our values, our offering is a meaningful collection of provisions that stands the test of time.</p>
            </Col>
            <Col md={4}>
              <img alt="Gardening" src={require('./Images/GettyImages-605379425.jpg')} className="gallery--image"/>
              <p className="canelaL gallery--title">Our Intention</p>
              <p className="spadeL gallery--description">Championing slow-living in a fast world we hope to  inspire people to experience their precious relationship with all things that grow. We want to connect deeply with our customers by listening to their needs and providing them with educational and inspiring content that elevates their experience of gardening and gathering.</p>
            </Col>
            <Col md={4}>
              <img alt="Gardening" src={require('./Images/bekah-russom-QFQkvg5qElw-unsplash.jpg')} className="gallery--image"/>
              <p className="canelaL gallery--title">Our Hope</p>
              <p className="spadeL gallery--description">We know that the act of gardening can awaken a desire to be better stewards of our environment. By encouraging people to get outside, get their hands in the dirt and then share the fruits of their labor in a beautiful setting we hope to inspire people to make conscious choices about their lifestyle and its impact on our collective future.</p>
            </Col>
          </Row>
          {/* <Row>
            <p className="canelaL gallery--information">
            HAPPENING HERE BEFORE <br/>THE DAHLIAS BLOOM...
            </p>
          </Row> */}
          <Row className="founder" style={{paddingTop:'15%'}}>
            <Col md={5}>
              <img alt="Gardening" src={require('./Images/SpadeAndPlate_Spring2022-9.jpg')} className="founder--image"/>
            </Col>
            <Col md={5} className="founder--content">
              <p className="canelaL gallery--title" style={{paddingTop:'0%'}}>Our Founder</p>
              <p className="spadeL gallery--description">
              As one of Canada’s top interior designers Kelly Deck is no stranger to creating beautiful spaces. She spends her weeks designing luxury estates with her team but her evenings and weekends are all about family and her humble garden at home on Vancouver’s East side. <br/><br/>
              Kelly started gardening five years ago in response to increasing concerns about the environment and a desire to create meaningful meals for friends and family. Frequenting the local garden centers, Kelly was discouraged by the lack of beautiful garden provisions that are so common in the UK and France and the disturbing amount of disposable products on the shelves.<br/><br/>
              This was the spark for Spade and Plate - a desire to work everyday with beautiful tools and provisions that will last for decades and then set the table for a meaningful gathering of those we love<br/><br/>
              </p>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <img alt="Gardening" src={require('./Images/WateringCan_S_P.png')} className="water--image"/>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid className="footer">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <div className="footer--content">
              <p className="canelaM footer--text">If you love gardening as much as we do, let’s keep in touch.</p>
              <FormCheckInput onChange={(e)=>{setMail(e.target.value)}} placeholder={"Your Email"} type="email" className="footer--input shadow-none">

              </FormCheckInput>
              {showError&&<p className="spadeL gallery--description" style={{color:'#702E18', margin:0}}>{showError}</p>}
              <button className="spadeL footer--button" onClick={sendMail}>
                { spin?
                  <Spinner animation="border" size="sm" />
                  :"SEND"
                }
              </button>
            </div>
          </Col>
        </Row>
        {
          show && 
          <Row style={{position:'fixed', top:10, right:10}}>
            <Col md="12">
              <Alert key={"dark"} variant={"dark"} className="spadeM" style={{backgroundColor:'#E2D7C5', color:"#702E18"}}>
                Message Sent, we will get back to you shortly!
              </Alert>
            </Col>
          </Row>
        }
      </Container>
    </div>
    
  );
}

export default App;
